<template>
  <div class="vx-row">
    <!-- PDF Generator -->

      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :filename="`recette_${DateDebut}_${DateFin}_${officineName}`"
        :pdf-quality="1"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="95%"
        :html-to-pdf-options="htmlToPdfOptions"

        @progress="onProgress($event)"
        @beforeDownload="beforeDownload($event)"
        ref="html2Pdf"
      >
        <section class="p-10 pl-20" slot="pdf-content">
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <h5 class="underline"> {{officineName}} </h5>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-1/3 mt-base">

                </div>
                <div class="vx-col w-1/3 mt-base text-center">
                  <div class="titre_etat_regl p-1">
                    <h5>Opération de caisse recette</h5>
                  </div>
                </div>
              </div>
              <div class="vx-row mt-5">
                <div class="vx-col w-full text-center">
                  <p style="font-size: 15px">Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</p>
                </div>
              </div>
              <div class="vx-row mt-5">
                <!-- {{reglements_client}} -->
                <table class="w-full" cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th class="customth" colspan="1">Date</th>
                      <th class="customth" colspan="1">Heure</th>
                      <th class="customth" colspan="1">N°Pièce</th>
                      <th class="customth" colspan="1">Moyen</th>
                      <th class="customth" colspan="1">Opération</th>
                      <th class="customth" colspan="1">Caissier</th>
                      <th class="customth" colspan="1">Entrée</th>
                      <th class="customth" colspan="1">Espèce</th>
                      <th class="customth" colspan="1">Chèque</th>
                      <th class="customth" colspan="1">Banque</th>
                      <th class="customth" colspan="1">Sortie</th>
                    </tr>
                  </thead>
                  <tbody v-if="operation.length >0">
                    <template >
                      <tr :key="indextr" v-for="(tr, indextr) in operation" :class="[(nbreDeLignePdf(indextr)) ? 'html2pdf__page-break' : '' ,(nbreDeLigneStyle(indextr)) ? 'mt-20' : '' ]">

                        <td class="customtd" colspan="1">{{tr.dateOperation | moment("calendar", "July 10 2011") }} </td>
                        <td class="customtd" colspan="1">{{tr.createdAt | moment("HH:mm:ss") }} </td>
                        <td class="customtd" colspan="1">{{tr.codeOperation}} </td>
                        <td class="customtd" colspan="1">{{tr.modepaiement}}</td>
                        <td class="customtd" colspan="1">{{tr.typeOperation}}</td>
                        <td class="customtd" colspan="1">{{getUserById(tr.IdUser).nom}} {{getUserById(tr.IdUser).prenoms}}</td>
                        <td class="text-right text-primary customtd" colspan="1">
                          <span v-if="(tr.typeOperation === 'Règlement cmde cli') || (tr.typeOperation === 'Règlement cpt') || (tr.typeOperation === 'Initialisation') || (tr.typeOperation === 'Consultation')">
                            {{moneyFormatter(tr.montant)}}
                          </span>
                        </td>
                        <td class="text-right text-primary customtd" colspan="1">
                          <span v-if="((tr.typeOperation === 'Règlement cmde cli') || (tr.typeOperation === 'Règlement cpt') || (tr.typeOperation === 'Consultation')) && (tr.modepaiement === 'Espèce')">
                            {{moneyFormatter(tr.montant)}}
                          </span>
                        </td>
                        <td class="text-right text-primary customtd" colspan="1">
                          <span v-if="((tr.typeOperation === 'Règlement cmde cli') || (tr.typeOperation === 'Règlement cpt')|| (tr.typeOperation === 'Consultation'))&& tr.modepaiement === 'Chèque'">
                            {{moneyFormatter(tr.montant)}}
                          </span>
                        </td>
                        <td class="text-right text-primary customtd" colspan="1">
                          <span v-if="(tr.typeOperation === 'Versement Banque')">
                            {{moneyFormatter(tr.montant)}}
                          </span>
                        </td>
                        <td class="text-right text-danger customtd" colspan="1">
                          <span v-if="(tr.typeOperation === 'Approvisionnement Caisse Depense')">
                            {{moneyFormatter(tr.montant)}}
                          </span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <!-- <div class="html2pdf__page-break"/> -->
                  <tfoot>
                    <tr>
                      <th class="text-center customth" colspan="1" ></th>
                      <th class="text-center customth" colspan="1" ></th>
                      <th class="text-center customth" colspan="1" ></th>
                      <th class="text-center customth" colspan="1" ></th>
                      <th class="text-center customth" colspan="1" ></th>
                      <th class="text-center customth" colspan="1" ></th>
                      <th class="text-center customth" colspan="1" >Entrée</th>
                      <th class="text-center customth" colspan="1" >Espèce</th>
                      <th class="text-center customth" colspan="1" >Chèque</th>
                      <th class="text-center customth" colspan="1" >Banque</th>
                      <th class="text-center customth" colspan="1" >Sortie</th>
                    </tr>
                    <tr>
                      <th class="customth text-right" colspan="6">
                        <span>Total</span>
                      </th>
                      <th class="text-right text-primary customth" colspan="1">
                        {{(moneyFormatter((firstInitialisationOnPeriodSelected ? firstInitialisationOnPeriodSelected.montant : 0) + totalEntree))}}
                      </th>
                      <th class="text-right text-primary customth" colspan="1">
                        {{moneyFormatter(totalEspece)}}
                      </th>
                      <th class="text-right text-primary customth" colspan="1">
                        {{moneyFormatter(totalCheque)}}
                      </th>
                      <th class="text-right text-primary customth" colspan="1">
                        {{moneyFormatter(totalVersementBanque)}}
                      </th>
                      <th class="text-right text-danger customth" colspan="1">
                        {{moneyFormatter(totalSortie)}}
                      </th>
                    </tr>
                    <tr>
                      <th class="customth text-right" colspan="6">
                        <span>Solde (espèce en caisse)</span>
                      </th>
                      <th class="text-right customth" colspan="5">
                        <template v-if="(solde >= 0)">
                          <span class="text-primary">
                            {{ moneyFormatter(solde) }}
                          </span>
                        </template>
                        <template v-else>
                          <span class="text-danger">
                            {{ moneyFormatter(solde) }}
                          </span>
                        </template>
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>

    <!-- PDF Generator end -->
    <div class="vx-col w-full">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col md:w-1/5 mt-4">
            <label class="text-sm">Date Debut</label>
            <flat-pickr v-model="DateDebut" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateDebut" />
          </div>
          <div class="vx-col md:w-1/5 mt-4">
            <label class="text-sm">Date Fin</label>
            <flat-pickr v-model="DateFin" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateFin" />
          </div>
          <div class="vx-col md:w-1/5 mt-4">
            <vs-select v-if="principale" label="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="onSelectOfficine(idOfficine)">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/5 mt-10">
            <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search" @click="getOperationPeriode()">Valider</vs-button>
          </div>
          <div class="vx-col md:w-1/5 mt-10">
            <vs-button id="pdf-button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-printer" @click="printCaisseToPdf()">Imprimer</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <div  class="vx-col w-full mt-5">
      <vx-card id="corps-proforma">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col w-full">
                <h5 class="underline"> {{officineName}} </h5>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-1/3 mt-base">

              </div>
              <div class="vx-col w-1/3 mt-base text-center">
                <div class="titre_etat_regl p-1">
                  <h3>Opération de caisse recette</h3>
                </div>
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full text-center">
                <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <!-- {{reglements_client}} -->
              <table class="w-full customtable" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th class="customth" colspan="1">Date</th>
                    <th class="customth" colspan="1">Heure</th>
                    <th class="customth" colspan="1">N°Pièce</th>
                    <th class="customth" colspan="1">Moyen</th>
                    <th class="customth" colspan="1">Opération</th>
                    <th class="customth" colspan="1">Caissier</th>
                    <th class="customth" colspan="1">Entrée</th>
                    <th class="customth" colspan="1">Espèce</th>
                    <th class="customth" colspan="1">Chèque</th>
                    <th class="customth" colspan="1">Banque</th>
                    <th class="customth" colspan="1">Sortie</th>
                  </tr>
                </thead>
                <tbody v-if="operation.length >0">
                  <template >
                    <tr :key="indextr" v-for="(tr, indextr) in operation">
                      <td class="customtd" colspan="1">{{tr.dateOperation | moment("calendar", "July 10 2011") }} </td>
                      <td class="customtd" colspan="1">{{tr.createdAt | moment("HH:mm:ss") }} </td>
                      <td class="customtd" colspan="1">{{tr.codeOperation}} </td>
                      <td class="customtd" colspan="1">{{tr.modepaiement}}</td>
                      <td class="customtd" colspan="1">{{tr.typeOperation}}</td>
                      <td class="customtd" colspan="1">{{getUserById(tr.IdUser) ? getUserById(tr.IdUser).nom : ''}} {{getUserById(tr.IdUser) ? getUserById(tr.IdUser).prenoms : ''}}</td>
                      <td class="text-right text-primary customtd" colspan="1">
                        <span v-if="(tr.typeOperation === 'Règlement cmde cli') || (tr.typeOperation === 'Règlement cpt') || (tr.typeOperation === 'Initialisation') || (tr.typeOperation === 'Consultation')">
                          {{moneyFormatter(tr.montant)}}
                        </span>
                      </td>
                      <td class="text-right text-primary customtd" colspan="1">
                        <span v-if="((tr.typeOperation === 'Règlement cmde cli') || (tr.typeOperation === 'Règlement cpt') || (tr.typeOperation === 'Consultation')) && (tr.modepaiement === 'Espèce')">
                          {{moneyFormatter(tr.montant)}}
                        </span>
                      </td>
                      <td class="text-right text-primary customtd" colspan="1">
                        <span v-if="((tr.typeOperation === 'Règlement cmde cli') || (tr.typeOperation === 'Règlement cpt')|| (tr.typeOperation === 'Consultation'))&& tr.modepaiement === 'Chèque'">
                          {{moneyFormatter(tr.montant)}}
                        </span>
                      </td>
                      <td class="text-right text-primary customtd" colspan="1">
                        <span v-if="(tr.typeOperation === 'Versement Banque')">
                          {{moneyFormatter(tr.montant)}}
                        </span>
                      </td>
                      <td class="text-right text-danger customtd" colspan="1">
                        <span v-if="(tr.typeOperation === 'Approvisionnement Caisse Depense')">
                          {{moneyFormatter(tr.montant)}}
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot>
                  <tr>
                    <th class="text-center customth" colspan="1" ></th>
                    <th class="text-center customth" colspan="1" ></th>
                    <th class="text-center customth" colspan="1" ></th>
                    <th class="text-center customth" colspan="1" ></th>
                    <th class="text-center customth" colspan="1" ></th>
                    <th class="text-center customth" colspan="1" ></th>
                    <th class="text-center customth" colspan="1" >Entrée</th>
                    <th class="text-center customth" colspan="1" >Espèce</th>
                    <th class="text-center customth" colspan="1" >Chèque</th>
                    <th class="text-center customth" colspan="1" >Banque</th>
                    <th class="text-center customth" colspan="1" >Sortie</th>
                  </tr>
                  <tr>
                    <th class="customth text-right" colspan="6">
                      <span>Total</span>
                    </th>
                    <th class="text-right text-primary customth" colspan="1">
                      {{(moneyFormatter((firstInitialisationOnPeriodSelected ? firstInitialisationOnPeriodSelected.montant : 0) + totalEntree))}}
                    </th>
                    <th class="text-right text-primary customth" colspan="1">
                      {{moneyFormatter(totalEspece)}}
                    </th>
                    <th class="text-right text-primary customth" colspan="1">
                      {{moneyFormatter(totalCheque)}}
                    </th>
                    <th class="text-right text-primary customth" colspan="1">
                      {{moneyFormatter(totalVersementBanque)}}
                    </th>
                    <th class="text-right text-danger customth" colspan="1">
                      {{moneyFormatter(totalSortie)}}
                    </th>
                  </tr>
                  <tr>
                    <th class="customth text-right" colspan="6">
                      <span>Solde (espèce en caisse)</span>
                    </th>
                    <th class="text-right customth" colspan="5">
                      <template v-if="(solde >= 0)">
                        <span class="text-primary">
                          {{ moneyFormatter(solde) }}
                        </span>
                      </template>
                      <template v-else>
                        <span class="text-danger">
                          {{ moneyFormatter(solde) }}
                        </span>
                      </template>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
/* lazy loading Vue components */
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const VueHtml2pdf = () => import('vue-html2pdf')

export default {
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: null,
      DateDebut: null,
      DateFin: null,
      idOfficine: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null,
      filename: '',
      officineName: '',
      nbrLigne: 0,
      htmlToPdfOptions: {
        margin: [0.30, 0.20, 0.40, 0],

        filename: 'operation_recette.pdf',

        image: {
          type: 'jpeg',
          quality: 0.98
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          useCORS: true
        },

        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'landscape'
        }
      }
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    reglements_client () {
      return this.$store.state.etat.reglements
    },
    operation () {
      return this.$store.state.operation.operations
    },
    firstInitialisationOnPeriodSelected () {
      const operations = this.$store.state.operation.operations

      return (operations && operations.length > 0) ? operations.find((o) => o.typeOperation === 'Initialisation') : null
    },
    totalEntree () {
      const operations = this.$store.state.operation.operations
      const montant = (operations && operations.length > 0) ? operations.reduce((acc, operation) => {
        return acc + +(operation.typeOperation === 'Règlement cmde cli' || operation.typeOperation === 'Règlement cpt' || operation.typeOperation === 'Consultation' ? operation.montant : 0)
      }, 0) : 0

      return montant
    },
    totalEspece () {
      const operations = this.$store.state.operation.operations
      const montant = (operations && operations.length > 0) ? operations.reduce((acc, operation) => {
        return acc + +((operation.typeOperation === 'Règlement cmde cli' || operation.typeOperation === 'Règlement cpt' || operation.typeOperation === 'Consultation') && operation.modepaiement === 'Espèce' ? operation.montant : 0)
      }, 0) : 0

      return montant + (this.firstInitialisationOnPeriodSelected ? this.firstInitialisationOnPeriodSelected.montant : 0)
    },
    totalCheque () {
      const operations = this.$store.state.operation.operations
      const montant = (operations && operations.length > 0) ? operations.reduce((acc, operation) => {
        return acc + +((operation.typeOperation === 'Règlement cmde cli' || operation.typeOperation === 'Règlement cpt' || operation.typeOperation === 'Consultation') && operation.modepaiement === 'Chèque'  ? operation.montant : 0)
      }, 0) : 0

      return montant
    },
    totalVersementBanque () {
      const operations = this.$store.state.operation.operations
      const montant = (operations && operations.length > 0) ? operations.reduce((acc, operation) => {
        return acc + +(operation.typeOperation === 'Versement Banque' ? operation.montant : 0)
      }, 0) : 0

      return montant
    },
    totalSortie () {
      const operations = this.$store.state.operation.operations
      const montant = (operations && operations.length > 0) ? operations.reduce((acc, operation) => {
        return acc + +(operation.typeOperation === 'Approvisionnement Caisse Depense' ? operation.montant : 0)
      }, 0) : 0

      return montant
    },
    solde () {
      return this.totalEspece - (this.totalSortie + this.totalVersementBanque)
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    }
  },
  methods: {
    getUserById (id) {
      return this.$store.getters['userManagement/getUserById'](id)
    },
    /* exportToPDF () {
      html2pdf(this.$refs.document, {
        margin: 1,
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      })
    }, */
    nbreDeLignePdf (index) {
      if (((index + 1) % 12) === 0) {
        return true
      } else {
        return false
      }
    },
    nbreDeLigneStyle (index) {
      if (((index + 1) % 13) === 0) {
        return true
      } else {
        return false
      }
    },
    async onProgress ({html2pdf, options, pdfContent}) {
      console.log('options:', options)
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        console.log('pdf:', pdf)
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text(`Page ${  i  } / ${  totalPages}`, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).save()
    },
    async beforeDownload ({html2pdf, options, pdfContent}) {
      console.log('options:', options)
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        console.log('pdf:', pdf)
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text(`Page ${  i  } / ${  totalPages}`, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      }).save()
    },
    printCaisseToPdf () {

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#pdf-button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      setTimeout(() => {
        this.$vs.loading.close('#pdf-button-with-loading > .con-vs-loading')
      }, 10000)

      this.$refs.html2Pdf.generatePdf()

    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    onSelectOfficine (id) {
      const officine = this.$store.state.officine.officines.find((o) => o._id === id)
      this.officineName = officine.NomOffic
    },
    getOperationPeriode () {
      const payload = {
        gteDate: this.DateDebut,
        ltDate: this.DateFin,
        Officine: this.idOfficine
      }

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      this.$store.dispatch('operation/getOperationPeriode', payload)
        .then(() => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })

    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {
          this.onSelectOfficine(this.idOfficine)
        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.onSelectOfficine(JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    },
    fetchUsers () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('userManagement/fetchUsers')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.userManagement.users.length > 0)) this.fetchUsers()
    this.activeUserInfo()
  }
}
</script>
<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
/* th, td {
  border: 1px solid black;
  padding: 4px;
} */

.customtable, .customth, .customtd {
  border: 0.5px solid black;
  border-collapse: collapse;
  /* font-family: 'Times New Roman'; */
  font-size: 12px;
}

.customtd {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.titre_etat_regl{
  border: 1px solid;
  background-color: #ECF8ED;
}
</style>
